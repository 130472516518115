import React, { Component } from "react";
import './Datos.scss';
import iconUsuarioVerde from '../../images/icons/iconUsuarioGreen.png';
import { firestorage, auth } from "../../firebase";
import moment from 'moment';
import {faImage,faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class Datos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUploadPhoto:false,
            errorInFileUpload:'',
            showConfirmButton:false,
            fileUploadText:'Pulse o arrastre para subir nuevo archivo'
        };
    }
    async uploadProfilePicture() {
        let input = document.getElementById('imageFileInput');
        var ref = firestorage.ref(auth.currentUser.uid + '/profilePicture/profile.png');
        ref.put(input.files[0]).then(async snapshot => {
            console.log('Foto de perfil subida correctamente');
            let url = await ref.getDownloadURL();
            auth.currentUser.updateProfile({
                photoURL: url
            }).then(()=> {
                console.log('Información de perfil actualizada');
                this.closeModalPhoto();
                window.location.reload();
            }).catch(function (error) {
                console.error(error);
                console.error('Información de perfil no actualizada');
                this.setState({errorInFileUpload:'Error actualizando la imagen'});
            });
        });
    }
    closeModalPhoto(){
        this.setState({showUploadPhoto:false,showConfirmButton:false,errorInFileUpload:'',fileUploadText:'Pulse o arrastre para subir nuevo archivo'});
    }
    openModalPhoto(){
        this.setState({showUploadPhoto:true});
    }
    onFileChanged(e){
        if(e.target.files.length>1){
            this.setState({errorInFileUpload:'Por favor, seleccione un único archivo'});
            setTimeout(()=>{
                this.setState({errorInFileUpload:''});
            },3000);
            return;
        }
        if (!['image/gif', 'image/jpeg', 'image/png'].includes(e.target.files[0]['type'])) {
            this.setState({errorInFileUpload:'Por favor, seleccione un formato válido'});
            setTimeout(()=>{
                this.setState({errorInFileUpload:''});
            },3000);
            return;
        }
        this.setState({fileUploadText:e.target.files[0].name});
        this.setState({showConfirmButton:true});
    }
    render() {
        return (
            <div className="datosComponent">
                {this.state.showUploadPhoto && <div className="modal">
                    <div className="modalContent">
                        <div className="closeIcon"><FontAwesomeIcon icon={faTimes}  onClick={(e) => this.closeModalPhoto()} /></div>
                        <input type="file" id="imageFileInput" accept="image/png, image/jpeg"  onChange={(e) => this.onFileChanged(e)}/>
                        <FontAwesomeIcon icon={faImage} />
                        {this.state.errorInFileUpload && <p className="error">{this.state.errorInFileUpload}</p>}
                        {this.state.showConfirmButton && <button className="confirm"  onClick={(e) => this.uploadProfilePicture()}>Confirmar</button>}
                        <p className="fileName">{this.state.fileUploadText}</p>
                    </div>
                </div>}
                <div className="title">Datos del titular</div>
                <div className="mainCard">
                    <div className="fotoDiv">
                        {this.props.userData.photo ? <img src={this.props.userData.photo}></img> : <img src={iconUsuarioVerde}></img>}                       
                        <div className="anadirFoto" onClick={(e) => this.openModalPhoto()}>
                            <FontAwesomeIcon icon={faImage} />
                            <p>Añadir foto</p>
                        </div>
                    </div>
                    <div className="contentDiv">
                        <div>
                            <p>{this.props.userData.name} {this.props.userData.surname1}</p>
                            <div>
                                {this.props.userData.lastAccess && <p>Último acceso a zona de cliente {moment(auth.currentUser.metadata.lastSignInTime).format('DD/MM/YYYY HH:mm')}</p>}
                                {/* <p className="modificarDatos">Modificar mis datos personales</p> */}
                            </div>
                        </div>
                        <div><p>{this.props.userData.nif  || this.props.userData.cif}</p></div>
                        <div><p>{this.props.userData.address}</p></div>
                        <div><p>{this.props.userData.email}</p></div>
                        {/* <div><p>{this.props.userData.iban}</p></div> */}
                        <div><p>{this.props.userData.telephone}</p></div>
                        {/* <div><p>Telefono2 del titular</p></div> */}
                    </div>
                </div>
            </div>
        );
    }
};
