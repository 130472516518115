import React, { Component } from "react";
import './ContratosLuz.scss';
import LineaContrato from "./LineaContrato";
import { auth, firestore } from "../../firebase";

export default class ContratosLuz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contracts:[]
        };
    }
    componentDidMount() {
        this.loadLightContracts();
    }
    async loadLightContracts(){
        let contratosLuz = (await firestore.collection("clients").doc(auth.currentUser.uid).collection('supplies').where("type", "==", "light").get());
        let contractsToAdd=[];
        contratosLuz.forEach((doc)=> {
            let data = doc.data();
            contractsToAdd.push({
                name:data.name,
                cups:doc.id,
                supplyName:data.name,
                supplyRef:data.reference,
                pdf:data.pdf
            });
        });
        this.setState({contracts:contractsToAdd});
    }
    render() {
        return (
            <div className="contratosLuzComponent">
                <div className="title">Contratos de luz</div>
                <div className="contractsDiv">
                    {this.state.contracts.map((x,i)=>{
                        return (<LineaContrato name={x.name} reference={x.supplyRef} url={x.pdf} fileName={"ContratoLuz-"+x.name+".pdf"} key={i}/>)
                    })}
                </div>
            </div>
        );
    }
};
