  
import React, {useState} from "react";
import './Loader.scss';
// import { auth } from "../../firebase";

const Loader = () => {

    return (
        <div className="loaderComponent">
            <div className="loader"></div>
        </div>
    );
};
export default Loader;