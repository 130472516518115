import React, { Component } from "react";
import './LineaPorSuministro.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import iconEditarVerde from '../../images/icons/iconEditGreen.png';
import iconContratoVerde from '../../images/icons/iconContratoGreen.png';
import iconDescargaVerde from '../../images/icons/iconDownloadGreen.png';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { firestorage, auth, firestore } from "../../firebase";
import moment from 'moment';

var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
var años = [2019, 2020, 2021];
export default class LineaPorSuministro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            month: 0,
            year: 2019,
        };
    }
    componentDidMount() {
    }

    handleMonthChange = (event) => {
        this.setState({ month: event.target.value });
    }
    handleYearChange = (event) => {
        this.setState({ year: event.target.value });
    }
    openInNewTab(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then(function (url) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
                var blob = xhr.response;
                const fileURL = URL.createObjectURL(blob);
                var win = window.open(url, '_blank');
                win.focus();
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
        });

    }
    download(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                var blob = xhr.response;
                const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = "Factura-#"+this.props.reference+".pdf";
                link.click();
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
        });
    }
    downloadAllFrom() {
        let date = new Date(this.state.year, this.state.month, 1);
        let promisesFacturasToDownload = [];
        this.props.bills.forEach(factura => {
            if (factura.date.toDate() >= date) {
                promisesFacturasToDownload.push(firestorage.refFromURL(factura.pdf).getDownloadURL());
            }
        });
        Promise.all(promisesFacturasToDownload).then(urls => {
            urls.forEach(url => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    var blob = xhr.response;
                    const fileURL = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = "Factura-#"+this.props.reference+".pdf";
                    link.click();
                };
                xhr.open('GET', url);
                xhr.send();
            });
        });
    }
    render() {
        return (
            <div className="lineaPorSuministroComponent">
                <div className={`generalDiv ${this.state.opened && "active"}`}>
                    <img className="editIcon" src={iconEditarVerde} />
                    <p className="name">{this.props.name}</p>
                    <div className="separator"></div>
                    <p className="reference">#{this.props.reference}</p>
                    <div className="openDiv" onClick={(e) => this.setState({ opened: !this.state.opened })}>
                        {this.state.opened ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                    </div>
                </div>
                {this.state.opened && <div className="specificDiv">
                    <div className="searchDiv">
                        <p>Todas las facturas desde</p>
                        <div className="selectsDiv">
                            <Select className="customSelect monthSelect" value={this.state.month} onChange={(e) => this.handleMonthChange(e)}>
                                {meses.map((mes, i) => {
                                    return <MenuItem value={i} key={i}>{mes}</MenuItem>
                                })}
                            </Select>
                            <Select className="customSelect" value={this.state.year} onChange={(e) => this.handleYearChange(e)}>
                                {años.map((año, i) => {
                                    return <MenuItem value={año} key={i}>{año}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <button className="downloadButton" onClick={(e) => this.downloadAllFrom()}>DESCARGAR</button>
                    </div>
                    <div className="visualiceDiv">
                        <div className="headersRow row">
                            <div className="column1">Fecha emisión</div>
                            <div className="column2">Nº Factura</div>
                            <div className="column3">Importe</div>
                            <div className="column4"></div>
                        </div>
                        {this.props.bills.map((bill, i) => {
                            return <div className="row" key={i}>
                                <div className="column1">{moment(bill.date.toDate()).locale('es').format('DD. MMM YYYY')}</div>
                                <div className="column2">#{bill.reference}</div>
                                <div className="column3">{bill.total + " €"}</div>
                                <div className="column4">
                                    <img src={iconContratoVerde} onClick={(e) => this.openInNewTab(bill.pdf)} />
                                    <img src={iconDescargaVerde} onClick={(e) => this.download(bill.pdf)} />
                                </div>
                            </div>
                        })}
                    </div>
                </div>}
            </div>
        );
    }
};
