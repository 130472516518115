
import React, { useState } from "react";
import './Login.scss';
import { auth } from "../../firebase";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showRecoveryModal, setShowRecoveryModal] = useState(false);
    const [emailToRecover, setEmailToRecover] = useState('');
    const [messageInModal, setMessageInModal] = useState(null);
    const [error, setError] = useState(null);
    const signInWithEmailAndPasswordHandler =
        (event, email, password) => {
            event.preventDefault();
            auth.signInWithEmailAndPassword(email, password).catch(function (error) {
                setError("Error de inicio de sesión");
                setTimeout(() => {
                    setError(null);
                }, 2000);
            });
        };

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        }
        else if (name === 'userPassword') {
            setPassword(value);
        }else if (name === 'emailToRecover') {
            setEmailToRecover(value);
        }
    };
    const sendRecoveryEmail = (email) => {
        auth.sendPasswordResetEmail(email)
        .then(() => {
            setMessageInModal({text:'Enviado correctamente',error:false});
            setTimeout(()=>{
                setMessageInModal(null);
                setShowRecoveryModal(false);
            },3000);
        })
        .catch((error)=> {
            setMessageInModal({text:'Error enviando correo de recuperación',error:true});
            setTimeout(()=>{
                setMessageInModal(null);
            },3000);
        })
    }
    const closeOpenModalRecovery= ()=>{
        setShowRecoveryModal(!showRecoveryModal);
    }

    return (
        <div className="loginComponent">
            {showRecoveryModal && <div className="modal">
                    <div className="modalContent">
                        <div className="closeIcon"><FontAwesomeIcon icon={faTimes}  onClick={(e) => closeOpenModalRecovery()} /></div>
                        <input placeholder="Correo electrónico" type="email" name="emailToRecover" value={emailToRecover} onChange={(event) => onChangeHandler(event)}/>
                        {messageInModal && <p className={`messageInModal ${messageInModal.error ? "error" : "right"}`}>{messageInModal.text}</p>}
                        <button onClick={(e) => sendRecoveryEmail(emailToRecover)}>Enviar correo de recuperación</button>
                    </div>
                </div>}
            <div className="infoDiv">
                <p className="logo">berde</p>
                <p className="title">Acceso Área Clientes</p>
                <p className="subTitle">Para acceder a nuestro área de clientes necesitas tener contratado Berde.</p>
            </div>
            <div className="formDiv">
                <p className="title">Login</p>
                <div className="inputsDiv">
                    <form className="">
                        <input placeholder="Correo electrónico" className="email" type="email" name="userEmail" value={email} id="userEmail" onChange={(event) => onChangeHandler(event)}></input>
                        <input placeholder="Contraseña" className="password" type="password" name="userPassword" value={password} id="userPassword" onChange={(event) => onChangeHandler(event)}></input>
                        <p className="recuperarContrasena" onClick={(e) => closeOpenModalRecovery()}>Recuperar Contraseña</p>
                        {error !== null && <div className="error">{error}</div>}
                        <button onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}>CONTINUAR</button>
                    </form>
                </div>

                <div className="infoAdicionalDiv">
                    <p>Teléfono gratuito <b>900 902 886</b></p>
                    <p>©2020 Todos los derechos reservados. Berde® es una marca registrada de Energía Colectiva SL.</p>
                    <div className="linksDiv">
                        <a><b>Cookies</b></a>
                        <a><b>Privacidad</b></a>
                        <a><b>Condiciones</b></a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;