import React, { Component } from "react";
import './Skeleton.scss';
import iconUsuarioVerde from '../../images/icons/iconUsuarioGreen.png';
import iconUsuarioAzul from '../../images/icons/iconUsuarioBlue.png';
import iconConfigVerde from '../../images/icons/iconConfigGreen.png';
import iconConfigAzul from '../../images/icons/iconConfigBlue.png';
import iconFacturaVerde from '../../images/icons/iconFacturaGreen.png';
import iconFacturaAzul from '../../images/icons/iconFacturaBlue.png';
import iconContratoAzul from '../../images/icons/iconContratoBlue.png';
import iconContratoVerde from '../../images/icons/iconContratoGreen.png';
import iconPassVerde from '../../images/icons/iconPassGreen.png';
import iconPassAzul from '../../images/icons/iconPassBlue.png';
import iconLightVerde from '../../images/icons/iconLightGreen.png';
import iconLightAzul from '../../images/icons/iconLightBlue.png';
import iconGasVerde from '../../images/icons/iconGasGreen.png';
import iconGasAzul from '../../images/icons/iconGasBlue.png';
import { auth, firestore } from "../../firebase";
import Datos from '../Datos/Datos';
import FacturasLuz from '../FacturasLuz/FacturasLuz';
import FacturasGas from '../FacturasGas/FacturasGas';
import ContratosLuz from '../ContratosLuz/ContratosLuz';
import ContratosGas from '../ContratosGas/ContratosGas';
import CambioPassword from "../CambioPassword/CambioPassword";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight,faAngleDown} from '@fortawesome/free-solid-svg-icons'





export default class Skeleton extends Component {
    //Primera posicion la seccion y la segunda la subseccion
    constructor(props) {
        super(props);
        this.state = {
            viewedSection: [0, 0],
            billsDisplayed: false,
            contractsDisplayed: false,
            configsDisplayed: false,
            userData: {}
        };
    }
    singOut(event) {
        event.preventDefault();
        auth.signOut();
    };
    componentDidMount() {
        this.loadName();
    }
    changeSection(viewedSection){
        this.setState({viewedSection:viewedSection});
    }
    changeBillsDisplay(){
        this.setState({billsDisplayed:!this.state.billsDisplayed});
    }
    changeContractsDisplay(){
        this.setState({contractsDisplayed:!this.state.contractsDisplayed});
    }
    changeConfigsDisplay(){
        this.setState({configsDisplayed:!this.state.configsDisplayed});
    }
    async loadName() {
        let userData = (await firestore.collection("clients").doc(auth.currentUser.uid).get()).data();
        userData.photo = auth.currentUser.photoURL;
        this.setState({ userData: userData });
    }

    render() {
        return (
            <div className="skeletonComponent">
                <div className="menu">
                    <p className="logo">berde</p>
                    <div className="navbar">
                        {/* Mis datos */}
                        <div className="separator firstSeparator"></div>
                        <div className={`navItem ${this.state.viewedSection[0] === 0 && "activeSubsection"}`}  onClick={(e) => this.changeSection([0,0])}>
                            <div className="iconDiv">
                            {this.state.viewedSection[0] === 0 ? <img src={iconUsuarioAzul}></img>:<img src={iconUsuarioVerde}></img>}
                            </div>
                            <p className="text">Mis datos</p>
                            <div className="arrowDiv">

                            </div>
                        </div>
                        {/* Facturas */}
                        <div className="separator"></div>
                        <div className={`navItem ${this.state.viewedSection[0] === 1 && "activeSection"}`} onClick={(e) => this.changeBillsDisplay()}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 1 ? <img src={iconFacturaAzul}></img>:<img src={iconFacturaVerde}></img>}
                            </div>
                            <p className="text">Mis facturas</p>
                            <div className="arrowDiv">
                                {this.state.billsDisplayed ? <FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleRight} />}        
                            </div>
                        </div>      
                        { this.state.billsDisplayed && (<div><div className={`separator ${this.state.viewedSection[0] === 1 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection ${this.state.viewedSection[0] === 1 && "activeSection"} ${this.state.viewedSection[0] === 1 && this.state.viewedSection[1] === 0 && "activeSubsection"}`} onClick={(e) => this.changeSection([1,0])}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 1 ? <img src={iconLightAzul}></img>:<img src={iconLightVerde}></img>}
                            </div>
                            <p className="text">Luz</p>
                        </div>
                        <div className={`separator ${this.state.viewedSection[0] === 1 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection ${this.state.viewedSection[0] === 1 && "activeSection"} ${this.state.viewedSection[0] === 1 && this.state.viewedSection[1] === 1 && "activeSubsection"}`} onClick={(e) => this.changeSection([1,1])}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 1 ? <img src={iconGasAzul}></img>:<img src={iconGasVerde}></img>}
                            </div>
                            <p className="text">Gas</p>
                        </div></div>)}
                        {/* Contratos */}
                        <div className="separator"></div>
                        <div className={`navItem ${this.state.viewedSection[0] === 2 && "activeSection"}`}  onClick={(e) => this.changeContractsDisplay()}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 2 ? <img src={iconContratoAzul}></img>:<img src={iconContratoVerde}></img>}
                            </div>
                            <p className="text">Mis contratos</p>
                            <div className="arrowDiv">
                                {this.state.contractsDisplayed ? <FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleRight} />}
                            </div>
                        </div>
                        { this.state.contractsDisplayed && (<div><div className={`separator ${this.state.viewedSection[0] === 2 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection ${this.state.viewedSection[0] === 2 && "activeSection"} ${this.state.viewedSection[0] === 2 && this.state.viewedSection[1] === 0 && "activeSubsection"}`} onClick={(e) => this.changeSection([2,0])}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 2 ? <img src={iconLightAzul}></img>:<img src={iconLightVerde}></img>}
                            </div>
                            <p className="text">Luz</p>
                        </div>
                        <div className={`separator ${this.state.viewedSection[0] === 2 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection ${this.state.viewedSection[0] === 2 && "activeSection"} ${this.state.viewedSection[0] === 2 && this.state.viewedSection[1] === 1 && "activeSubsection"}`} onClick={(e) => this.changeSection([2,1])}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 2 ? <img src={iconGasAzul}></img>:<img src={iconGasVerde}></img>}
                            </div>
                            <p className="text">Gas</p>
                        </div></div>)}
                        {/* Configuracion */}
                        <div className="separator"></div>
                        <div className={`navItem ${this.state.viewedSection[0] === 3 && "activeSection"}`}  onClick={(e) => this.changeConfigsDisplay()}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 3 ? <img src={iconConfigAzul}></img>:<img src={iconConfigVerde}></img>}
                            </div>
                            <p className="text">Configuración</p>
                            <div className="arrowDiv">
                                {this.state.configsDisplayed ? <FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleRight} />}
                            </div>
                        </div>
                        { this.state.configsDisplayed && (<div><div className={`separator ${this.state.viewedSection[0] === 3 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection ${this.state.viewedSection[0] === 3 && "activeSection"} ${this.state.viewedSection[0] === 3 && this.state.viewedSection[1] === 0 && "activeSubsection"}`} onClick={(e) => this.changeSection([3,0])}>
                            <div className="iconDiv">
                                {this.state.viewedSection[0] === 3 ? <img src={iconLightAzul}></img>:<img src={iconLightVerde}></img>}
                            </div>
                            <p className="text">Cambiar contraseña</p>
                        </div>
                        <div className={`separator ${this.state.viewedSection[0] === 3 && "activeSeparator"}`}></div>
                        <div className={`navItem subsection cerrarSesionSubsection ${this.state.viewedSection[0] === 3 && "activeSection"}`}>
                            <button className={`signOut ${this.state.viewedSection[0] === 3 && "active"}`} onClick={(e) => this.singOut(e)}>Cerrar sesión</button>
                        </div>
                        </div>)}
                        <div className="separator"></div>
                    </div>
                </div>
                <div className="contentAndHeader">
                    <div className="header">
                        <div className="userInfoDiv">
                        {this.state.userData.photo ? <img src={this.state.userData.photo}></img>:<img src={iconUsuarioVerde}></img> }
                            <p>{this.state.userData.name || ''} {this.state.userData.surname1 || ''}</p>
                        </div>
                        <div className="numberDiv">
                            <p>Atención clientes: 900 525 660</p>
                        </div>

                        <button className="signOut" onClick={(e) => this.singOut(e)}>Cerrar sesion</button>
                    </div>
                    <div className="content">
                        {this.state.viewedSection[0] === 0 && <Datos userData={this.state.userData}/>}
                        {this.state.viewedSection[0] === 1 && this.state.viewedSection[1] === 0  && <FacturasLuz/>}
                        {this.state.viewedSection[0] === 1 && this.state.viewedSection[1] === 1 && <FacturasGas/>}
                        {this.state.viewedSection[0] === 2 && this.state.viewedSection[1] === 0 && <ContratosLuz/>}
                        {this.state.viewedSection[0] === 2 && this.state.viewedSection[1] === 1 && <ContratosGas/>}
                        {this.state.viewedSection[0] === 3 && <CambioPassword/>}
                    </div>
                </div>
            </div>
        );
    }
}
// export default Skeleton;