import React, { Component } from "react";
import './FacturasLuz.scss';
import LineaPorSuministro from "./LineaPorSuministro";
import { auth, firestore } from "../../firebase";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import LineaPorFecha from "./LineaPorFecha";
var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
var años = [2019, 2020, 2021];
export default class FacturasLuz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionSelected: 0,
            suministros: [],
            month: 11,
            year: 2019,
        };
    }
    componentDidMount() {
        this.loadSupplies();
    }
    async loadSupplies() {
        let suministros = (await firestore.collection("clients").doc(auth.currentUser.uid).collection('supplies').where("type", "==", "light").get());
        let suministrosToAdd = [];
        let promisesFacturas = [];
        suministros.forEach(suministro => {
            let data = suministro.data();
            data.cups=suministro.id
            promisesFacturas.push(firestore.collection("clients").doc(auth.currentUser.uid).collection('supplies').doc(suministro.id).collection('bills').get());
            suministrosToAdd.push({
                facturas: [],
                supplyName: data.name,
                supplyRef: data.reference,
            });
        });
        Promise.all(promisesFacturas).then(responses => {
            responses.forEach((response, i) => {
                response.forEach(doc => {
                    suministrosToAdd[i].facturas.push(doc.data());
                })
            });
        });
        this.setState({ suministros: suministrosToAdd });

    }
    handleMonthChange = (event) => {
        this.setState({ month: event.target.value });
    }
    handleYearChange = (event) => {
        this.setState({ year: event.target.value });
    }

    render() {
        return (
            <div className="facturasLuzComponent">
                <div className="title">Facturas de luz</div>
                <div className="selectionDiv">
                    <p className={`selectionItem ${this.state.sectionSelected === 0 && "active"}`} onClick={(e) => { this.setState({ sectionSelected: 0 }) }}>Mis suministros</p>
                    <p className={`selectionItem ${this.state.sectionSelected === 1 && "active"}`} onClick={(e) => { this.setState({ sectionSelected: 1 }) }}>Multipropietarios</p>
                </div>
                <div className="facturasDiv">
                    {this.state.sectionSelected === 0 && <div>
                        {this.state.suministros.map((suministro, i) => {
                            return <LineaPorSuministro key={i} name={suministro.supplyName} reference={suministro.supplyRef} bills={suministro.facturas} />
                        })}
                    </div>}
                    {this.state.sectionSelected === 1 && <div>
                        <div className="multiFilterDiv">
                            <p>Mostrar las facturas de</p>
                            <Select className="customSelect monthSelect" value={this.state.month} onChange={(e) => this.handleMonthChange(e)}>
                                {meses.map((mes, i) => {
                                    return <MenuItem value={i} key={i}>{mes}</MenuItem>
                                })}
                            </Select>
                            <p>del</p>
                            <Select className="customSelect" value={this.state.year} onChange={(e) => this.handleYearChange(e)}>
                                {años.map((año, i) => {
                                    return <MenuItem value={año} key={i}>{año}</MenuItem>
                                })}
                            </Select>
                            <p>de todos los suministros</p>
                        </div>
                        <div className="downloadDiv">
                            <p>Descarga todas las facturas de suministros</p>
                            <button className="downloadButton" onClick={(e) => this.downloadAllFrom()}>DESCARGAR</button>
                        </div>
                        <div className="visualiceDiv">
                            <div className="headersRow row">
                                <div className="column1">Suministro</div>
                                <div className="column2">Nº Factura</div>
                                <div className="column3">Importe</div>
                                <div className="column4"></div>
                            </div>
                            {this.state.suministros.filter(filterFunctionByDate.bind(this)).map((suministro, i) => {
                                let bill = suministro.facturas[suministro.facturaSelected];
                                return <LineaPorFecha key={i} name={suministro.supplyName} supplyReference={suministro.supplyRef} bill={bill}/>
                            })}
                        </div>


                    </div>}
                </div>
            </div>
        );
    }
};

function filterFunctionByDate(elto) {
    let result = false;
    for (let i = 0; i < elto.facturas.length; i++) {
        let year = moment(elto.facturas[i].date.toDate()).year();
        let month = moment(elto.facturas[i].date.toDate()).month();
        if (this.state.year === year && this.state.month === month) {
            elto.facturaSelected = i;
            result = true;
        }
    }
    return result;

}

