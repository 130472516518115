import React, { Component } from "react";
import './CambioPassword.scss';
import { auth, emailAuthProvider } from "../../firebase";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CambioPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword1: '',
            newPassword2: '',
            showRecoveryModal: false,
            emailToRecover: '',
            messageInModal: {
                error: true,
                text: ''
            },
            message: {
                error: true,
                text: ''
            }
        };
    }
    onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'oldPassword') {
            this.setState({ oldPassword: value });
        }
        else if (name === 'newPassword1') {
            this.setState({ newPassword1: value });
        } else if (name === 'newPassword2') {
            this.setState({ newPassword2: value });
        }else if (name === 'emailToRecover') {
            this.setState({ emailToRecover: value });
        }
    }
    updatePassword() {
        if (this.state.newPassword1 !== this.state.newPassword2) {
            this.setState({
                message: {
                    text: 'Las contraseñas no coinciden',
                    error: true
                }
            });
            setTimeout(() => {
                this.setState({
                    message: {
                        text: '',
                        error: false
                    }
                });
            }, 3000);
            return;
        }
        const user = auth.currentUser;
        const credential = emailAuthProvider.credential(
            user.email,
            this.state.oldPassword
        );
        user.reauthenticateWithCredential(credential).then(() => {
            user.updatePassword(this.state.newPassword1).then(() => {
                this.setState(
                    {
                        message: {
                            text: 'Contraseña actualizada correctamente',
                            error: false,
                        },
                        oldPassword: '',
                        newPassword1: '',
                        newPassword2: '',
                    });
                setTimeout(() => {
                    this.setState({
                        message: {
                            text: '',
                            error: false
                        }
                    });
                }, 3000);
            }).catch((error) => {
                this.setState({
                    message: {
                        text: 'No se ha podido actualizar la contraseña, intente más tarde',
                        error: true
                    }
                });
                setTimeout(() => {
                    this.setState({
                        message: {
                            text: '',
                            error: false
                        }
                    });
                }, 3000);
            });
        }).catch(error => {
            this.setState({
                message: {
                    text: 'Contraseña incorrecta',
                    error: true
                }
            });
            setTimeout(() => {
                this.setState({
                    message: {
                        text: '',
                        error: false
                    }
                });
            }, 3000);
        });
    }
    sendRecoveryEmail = (email) => {
        auth.sendPasswordResetEmail(email)
        .then(() => {
            this.setState({messageInModal:{text:'Enviado correctamente',error:false}});
            setTimeout(()=>{
                this.setState({messageInModal:null});
            },3000);
        })
        .catch((error)=> {
            this.setState({messageInModal:{text:'Error enviando correo de recuperación',error:true}});
            setTimeout(()=>{
                this.setState({messageInModal:null});
            },3000);
        })
    }
    closeOpenModalRecovery= ()=>{
        this.setState({showRecoveryModal:!this.state.showRecoveryModal});
    }
    render() {
        return (
            <div className="cambioPasswordComponent">
                {this.state.showRecoveryModal && <div className="modal">
                    <div className="modalContent">
                        <div className="closeIcon"><FontAwesomeIcon icon={faTimes}  onClick={(e) => this.closeOpenModalRecovery()} /></div>
                        <input placeholder="Correo electrónico" type="email" name="emailToRecover" value={this.state.emailToRecover} onChange={(event) => this.onChangeHandler(event)}/>
                        {this.state.messageInModal && <p className={`messageInModal ${this.state.messageInModal.error ? "error" : "right"}`}>{this.state.messageInModal.text}</p>}
                        <button onClick={(e) => this.sendRecoveryEmail(this.state.emailToRecover)}>Enviar correo de recuperación</button>
                    </div>
                </div>}
                <div className="title">Cambiar contraseña</div>
                <div className="formDiv">
                    <div>
                        <input placeholder="Contraseña antigua" className="oldPassword" type="password" name="oldPassword" value={this.state.oldPassword} id="oldPassword" onChange={(event) => this.onChangeHandler(event)}></input>
                        <input placeholder="Nueva constraseña" className="newPassword1" type="password" name="newPassword1" value={this.state.newPassword1} id="newPassword1" onChange={(event) => this.onChangeHandler(event)}></input>
                    </div>
                    <div>
                        <p onClick={(e) => { this.closeOpenModalRecovery()}}>¿No recuerdas tu contraseña?</p>
                        <input placeholder="Repetir nueva contraseña" className="newPassword2" type="password" name="newPassword2" value={this.state.newPassword2} id="newPassword2" onChange={(event) => this.onChangeHandler(event)}></input>
                    </div>
                    <div className="buttonDiv">
                        <p></p>
                        <button onClick={(e) => { this.updatePassword() }}>CONTINUAR</button>
                    </div>
                    {this.state.message.text && <div className={`message ${this.state.message.error && "error"}`}>
                        {this.state.message.text}
                    </div>}

                </div>
            </div>
        );
    }
};
