import firebase from 'firebase/app';
import { createContext } from 'react';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-storage';


////////////////////////////////////////////////////////////
////////////////////////Desarrollo//////////////////////////
////////////////////////////////////////////////////////////
// const firebaseConfig = {
// 	apiKey: "AIzaSyCQ6MHHlQtQ2i4JBFVzLSoGm9JX7qIO8zE",
// 	authDomain: "administracionberdedesarollo.firebaseapp.com",
// 	projectId: "administracionberdedesarollo",
// 	storageBucket: "administracionberdedesarollo.appspot.com",
// 	messagingSenderId: "833523505094",
// 	appId: "1:833523505094:web:94893f41d170ba1b1bc4c7"
// };

////////////////////////////////////////////////////////////
//////////Produccion, mucho cuidado con descomentar/////////
////////////////////////////////////////////////////////////
const firebaseConfig = {
	apiKey: 'AIzaSyCcIq9ZsPLikiWVJW3Bu_WhPKXY1K5NI0I',
	authDomain: 'administracionberde.firebaseapp.com',
	projectId: 'administracionberde',
	storageBucket: 'administracionberde.appspot.com',
	messagingSenderId: '1018855418428',
	appId: '1:1018855418428:web:434c3a5dbfdd50216a3139'
};
export const userContext = createContext({ user: null });
export default userContext;

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const firestorage = firebase.storage();
export const emailAuthProvider = firebase.auth.EmailAuthProvider;

//Para crear nuevos usarios
export const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");