import React, { Component } from 'react';
import Datos from './components/Datos/Datos';
import Loader from './components/Loader/Loader';
import userContext from './firebase';
import { BrowserRouter as Router } from 'react-router-dom';
import { auth,firestore } from './firebase';
import Login from './components/Login/Login';
import Skeleton from './components/Skeleton/Skeleton';
import moment from 'moment';


class App extends Component {
	state = {
		user: undefined,
		loading: true
	};
	componentDidMount() {
		auth.onAuthStateChanged((user) => {
			this.setState({ user: user, loading: false });			
		});
		this.setUpMoment();
	}
	setUpMoment(){
		moment.locale('es', {
			months : ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
			monthsShort : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
		});
	}
	render() {
		moment().locale();
		if (this.state.loading) {
			return <Loader/>;
		}
		return this.state.user == null ? (
			<Login />
		) : (
			<Router>
				<userContext.Provider value={this.state.user}>
					<Skeleton />
				</userContext.Provider>
			</Router>
		);
	}
}
export default App;
