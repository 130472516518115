import React, { Component } from "react";
import './LineaPorFecha.scss';
import iconContratoVerde from '../../images/icons/iconContratoGreen.png';
import iconDescargaVerde from '../../images/icons/iconDownloadGreen.png';
import { firestorage } from "../../firebase";

export default class LineaPorFecha extends Component {
    openInNewTab(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then(function (url) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
                var blob = xhr.response;
                const fileURL = URL.createObjectURL(blob);
                var win = window.open(url, '_blank');
                win.focus();
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
        });

    }
    download(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                var blob = xhr.response;
                const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = "Factura-#" + this.props.supplyReference + ".pdf";
                link.click();
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
        });
    }
    render() {
        return (
            <div className="lineaPorFechaComponent row" >
                <div className="column1">{this.props.name}</div>
                <div className="column2">#{this.props.bill.reference}</div>
                <div className="column3">{this.props.bill.total + " €"}</div>
                <div className="column4">
                    <img src={iconContratoVerde} onClick={(e) => this.openInNewTab(this.props.bill.pdf)} />
                    <img src={iconDescargaVerde} onClick={(e) => this.download(this.props.bill.pdf)} />
                </div>
            </div>
        );
    }
};
