import React, { Component } from "react";
import './LineaContrato.scss';
import iconContratoVerde from '../../images/icons/iconContratoGreen.png';
import iconDescargaVerde from '../../images/icons/iconDownloadGreen.png';
import iconEditarVerde from '../../images/icons/iconEditGreen.png';
import { firestorage, auth } from "../../firebase";
// import { auth } from "../../firebase";

export default class LineaContrato extends Component {
    constructor(props) {
        super(props);
    }
    openInNewTab(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then(function(url) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function(event) {
                var blob = xhr.response;
                const fileURL = URL.createObjectURL(blob);
                var win = window.open(url, '_blank');
                win.focus();
            };
            xhr.open('GET', url);
            xhr.send();
          }).catch(function(error) {
          });
        
    }
    download(url) {
        var httpsReference = firestorage.refFromURL(url);
        httpsReference.getDownloadURL().then((url)=> {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event)=> {
              var blob = xhr.response;
               const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = this.props.fileName;
                link.click();
            };
            xhr.open('GET', url);
            xhr.send();
          }).catch(function(error) {
          });
    }
    render() {
        return (
            <div className="lineaContratoComponent">
                <img className="editIcon" src={iconEditarVerde} />
                <p className="name">{this.props.name}</p>
                <div className="separator"></div>
                <p className="reference">#{this.props.reference}</p>
                <img src={iconContratoVerde} onClick={(e) => this.openInNewTab(this.props.url)} />
                <img src={iconDescargaVerde} onClick={(e) => this.download(this.props.url)} />
            </div>
        );
    }
};
